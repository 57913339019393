<template>
  <a :href="appUrl" class="button" target="_blank" rel="external">
    <img :src="appIcon" :alt="title" />
    {{ title }}
  </a>
</template>

<script>
import playStoreIcon from '@/assets/images/icons/play-store-icon.svg';
import appStoreIcon from '@/assets/images/icons/app-store-icon.svg';

export default {
  props: ['title', 'appUrl', 'storeType'],
  data: function () {
    return {
      appIcon: this.storeType == 'android' ? playStoreIcon : appStoreIcon,
    };
  },
};
</script>

<style lang="scss" >
@import '@/assets/styles/_variables.scss';
.button {
  padding: 15px 20px;
  font-size: 1.3em;
  color: $primary;
  font-weight: 500;
  background: #fff;
  box-shadow: 3px 3px 20px rgba(1, 1, 1, 0.1);
  border-radius: 15px;
  transition: all 200ms ease-in-out;
  img {
    max-width: 42px;
    margin-right: 4px;
  }
  &:hover {
    text-decoration: none;
    transform: scale(1.1);
  }
}

//lg
@media (max-width: 1199.98px) {
}

//md
@media (max-width: 991.98px) {
  // .button{
  //     padding: 6px 15px;
  //     font-size: 1em;
  //     border-radius: 10px;
  //     img{
  //         max-width: 30px;
  //     }
  // }
}

// sm
@media (max-width: 767.98px) {
}

// xs
@media (max-width: 575.98px) {
}
</style>