<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link class="navbar-brand" to="/">
      <img class="logo-icon" :src="require('@/assets/images/aha-logo-icon.svg')" alt="aruba health app logo" width="50" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/" exact> <i class="fas fa-mobile-alt"></i> <span class="link-text">App</span></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/privacy-policy"
            ><i class="far fa-file-alt"></i> <span class="link-text">Privacy Policy</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/terms"
            ><i class="far fa-file-alt"></i> <span class="link-text">Terms &amp; Conditions</span></router-link
          >
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" to="/instructions"><i class="far fa-file-alt"></i> <span class="link-text">Instructions</span></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/statistics"><i class="fas fa-chart-line"></i> <span class="link-text">Statistics</span></router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/azv-lookup"
            ><i class="fas fa-search"></i> <span class="link-text">AZV Appointment lookup</span></router-link
          >
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
export default { compatConfig: { MODE: 3 } };
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.navbar {
  //border-bottom: 1px solid lighten(lightgrey, 10%);
  .nav-item {
    .nav-link {
      margin: 10px 12px;
      &.router-link-active {
        color: $primary !important;
      }
      .link-text {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-nav {
    background: #fff;
  }
}
</style>
