import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

const app = createApp(App);
app.use(router);
app.mount('#app');

app.config.globalProperties.productionTip = false;

//global vars
app.config.globalProperties.$baseUrlApi = process.env.NODE_ENV === 'development' ? 'https://test.dvgapp.org' : 'https://api.dvgapp.org';
app.config.globalProperties.$androidAppUrl = 'https://play.google.com/store/apps/details?id=org.dvgapp.tourist';
app.config.globalProperties.$iOSAppUrl = 'https://apps.apple.com/us/app/id1518610159';
