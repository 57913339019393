// import { createApp } from 'vue';
// import App from '../App.vue';
// import VueRouter from 'vue-router';
// import HomePage from '../views/HomePage.vue';
// import store from './store';

// createApp(App).use(store).use(VueRouter).mount('#app');
// createApp(App).use(VueRouter).mount('#app');

// const routes = [
//   {
//     path: '/',
//     name: 'HomePage',
//     component: HomePage,
//   },
// {
//   path: '/cert',
//   name: 'ValidateCertification',
//   component: () => import('../views/ValidateCertification/ValidateCertification.vue')
// },
// {
//   path: '/statistics',
//   name: 'Statistics',
//   component: () => import('../views/Statistics/Statistics.vue')
// },
// {
//   path: '/azv-lookup',
//   name: 'AZV lookup',
//   component: () => import('../views/AzvLookup.vue')
// },
// {
//   path: '/vaccine-certification',
//   name: 'ValidateCertificationVaccine',
//   component: () => import('../views/ValidateCertificationVaccine/ValidateCertificationVaccine.vue')
// },
// {
//   path: '/instructions',
//   name: 'InstructionsMainView',
//   component: () => import('../views/Instructions/InstructionsMainView.vue')
// },
// {
//   path: '/recovery-certification',
//   name: 'RecoveryCertification',
//   component: () => import('../views/RecoveryCertification/RecoveryCertification.vue')
// }
// ];

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
// });

// const router = new VueRouter({
//   routes,
// });

// export default router;

import { createWebHistory, createRouter } from 'vue-router';

import HomePage from '../views/HomePage.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsOfUse from '../views/TermsOfUse.vue';

const routes = [
  { path: '/', component: HomePage, name: 'HomePage' },
  { path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy' },
  { path: '/terms', component: TermsOfUse, name: 'TermsOfUse' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
