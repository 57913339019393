<template>
  <section id="hero-section">
    <div class="container custom">
      <div class="row hero-wrapper">
        <div class="col-lg-6 order-2 order-lg-1 col-left">
          <img class="logo-icon" :src="require('@/assets/images/aha-logo-icon.svg')" alt="Aruba Health App logo" />

          <h1 class="title" data-aos="fade-right">Aruba Health App</h1>

          <div class="sub-text" data-aos="fade-down">
            With the Aruba Health App, you can schedule an appointment with your GP, use a self-triage system, view prescribed medicine and vaccines —
            all on the mobile app.
          </div>

          <div class="button-section">
            <div class="app-download-button-wrapper">
              <AppDownloadButton data-aos="zoom-in" class="app-button" title="Play Store" :appUrl="androidAppUrl" storeType="android" />

              <AppDownloadButton data-aos="zoom-in" data-aos-delay="100" class="app-button" title="App Store" :appUrl="iOSAppUrl" storeType="apple" />
            </div>
          </div>
        </div>

        <div class="col-lg-6 order-1 order-lg-2 hero-mobile-image-wrapper col-right">
          <img class="hero-mobile-image" :src="require('@/assets/images/phone-aruba-health-app.png')" alt="mobile phone image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppDownloadButton from '@/components/app-download-button';

export default {
  components: {
    AppDownloadButton,
  },
  data: function () {
    return {
      iOSAppUrl: this.$iOSAppUrl,
      androidAppUrl: this.$androidAppUrl,
    };
  },
  created() {},
  compatConfig: { MODE: 3 },
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
#hero-section {
  position: relative;
  min-height: calc(100vh - 0px);
  .hero-wrapper {
    padding-top: 80px;
    .col-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .col-right {
      display: flex;
      justify-content: center;
    }
    .logo-icon {
      max-width: 300px;
      margin: 0 auto 20px;
    }
    h1.title {
      color: $primary;
      font-weight: 600 !important;
      font-size: 3.5rem;
      //text-transform: uppercase;
    }

    .sub-text {
      font-size: 1.3rem;
      color: gray;
      font-weight: 400;
      line-height: 1.6em;
      margin-top: 30px;
      min-height: 80px;
    }

    .hero-mobile-image-wrapper {
      position: relative;
      overflow: hidden;
      .hero-mobile-image {
        //position: relative;
        //width: 120%;
        width: 320px;
        height: 639px;
        // margin-right: -100px;
      }
    }

    .button-section {
      display: inline-block;
      .app-download-button-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .app-button {
          margin-top: 50px;
          margin-right: 20px;
        }
      }

      .app-button.custom {
        margin-top: 30px;
        padding: 16px 24px;
        display: flex;
        height: 76px;
        .text {
          margin-left: 10px;
          .small {
            text-align: left;
            line-height: 1rem;
            font-size: 13px;
            font-weight: 600;
          }
          .large {
            margin-top: 3px;
            line-height: 1rem;
          }
        }
      }
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -15px;
    animation: upAndDownScroll 1s ease-in-out infinite alternate;
    span.fa {
      color: green;
      font-size: 3rem;
    }
  }
}

//lg
@media (max-width: 1199.98px) {
  #hero-section {
    .hero-wrapper {
      // .hero-mobile-image-wrapper {
      //     position: relative;
      //     .hero-mobile-image {
      //         position: static;
      //         max-width: 100%;
      //         right: 0;
      //     }
      // }
    }
  }
}

//md
@media (max-width: 991.98px) {
  #hero-section {
    min-height: calc(100vh - 0px);
    .hero-wrapper {
      padding-top: 40px;
      // .hero-mobile-image-wrapper {

      //   margin-bottom: 30px;
      //     .hero-mobile-image {
      //         position: static;
      //         max-width: 20%;
      //         margin: 0 auto;
      //         display: block;
      //     }
      // }
      .hero-mobile-image-wrapper {
        margin-bottom: 20px;
        .hero-mobile-image {
          //position: relative;
          //width: 120%;
          width: 190px;
          height: 365px;

          // margin-right: -100px;
        }
      }

      h1.title {
        font-size: 2rem;
        text-align: center;
      }

      .sub-text {
        font-size: 1.2rem;
        color: gray;
        font-weight: 400;
        line-height: 1.4em;
        margin-top: 20px;
        text-align: center;
        min-height: auto;
      }

      .button-section {
        display: block;
        .app-download-button-wrapper {
          .app-button {
            margin: 20px 10px 0px 10px;
            img {
              max-width: 20px;
            }
          }
        }

        .video-button-wrapper {
          clear: both;
          margin: 25px auto 40px;
          display: block;
        }
      }
    }
    .scroll-indicator {
      position: relative;
      left: auto;
      margin: 0;
      span {
        text-align: center;
        display: block;
      }
      //display: block;
    }
  }
}

// sm
@media (max-width: 767.98px) {
  #hero-section {
    .hero-wrapper {
      .hero-mobile-image-wrapper {
        display: none;
      }

      h1.title {
        font-size: 2rem;
        text-align: center;
      }

      .sub-text {
        font-size: 1rem;
        color: gray;
        font-weight: 400;
        line-height: 1.6em;
        margin-top: 0px;
        text-align: center;
        min-height: auto;
      }

      .button-section {
        display: block;
        .app-download-button-wrapper {
          .app-button {
            margin: 20px 10px 0px 10px;
            img {
              max-width: 20px;
            }
          }
        }

        .video-button-wrapper {
          clear: both;
          margin: 25px auto 40px;
          display: block;
        }
      }
    }
    .scroll-indicator {
      position: relative;
      left: auto;
      margin: 0;
      span {
        text-align: center;
        display: block;
      }
      //display: block;
    }
  }
}

// xs
@media (max-width: 575.98px) {
}

//xl screen custom
@media (max-width: 1399.98px) {
  #hero-section {
    .hero-wrapper {
      .hero-mobile-image-wrapper {
        .hero-mobile-image {
          //width: 95%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .container.custom {
    max-width: 1440px;
    height: 100%;
  }
}
</style>
