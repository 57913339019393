<template>
  <div id="app">
    <router-view />
  </div>
</template>


<style lang="scss">
@import '@/assets/styles/_variables.scss';
html,
body {
  min-height: 100%;
}
body {
  background: #f9f9f9 !important;
}
html {
  overflow-y: scroll;
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

h1,
h2,
h3 {
  font-family: 'Poppins', sans-serif;
}
</style>
