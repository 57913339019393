<template>
  <div class="home">
    <MainMenu />
    <HeroSection />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/hero-section.vue';
import MainMenu from '@/components/main-menu.vue';

export default {
  name: 'HomePage',
  components: {
    HeroSection,
    MainMenu,
  },
  compatConfig: { MODE: 3 },
};
</script>
