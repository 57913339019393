<template>
  <div class="privacy-policy">
    <MainMenu />
    <div class="container mt-5 text-left">
      <div class="row">
        <div class="col-lg-8">
          <h2 class="font-weight-bold mb-3">Privacy Policy</h2>
          <p class="p-text-large">Aruba Health App's Privacy Policy describes how Aruba Health App collects, uses, and shares your personal data.</p>
        </div>
      </div>
      <hr class="mb-4" />
      <div class="row mt-3">
        <div class="col-lg-6">
          <ul>
            <li>
              <a href="#" @click="downloadPrivacyStatement('en')">Aruba Health App Privacy Statement (English)</a>
            </li>
            <li>
              <a href="#" @click="downloadPrivacyStatement('nl')">Privacyverklaring Aruba Health App (Nederlands)</a>
            </li>
            <li>
              <a href="#" @click="downloadPrivacyStatement('es')"
                >Declaraci&oacute;n de Privacidad de la aplicaci&oacute;n Aruba Health (Espa&ntilde;ol)</a
              >
            </li>
            <li>
              <a href="#" @click="downloadPrivacyStatement('pap')">Declaracion di Privacidad Aruba Health App (Papiamento)</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from '@/components/main-menu.vue';
export default {
  components: {
    MainMenu,
  },
  methods: {
    downloadPrivacyStatement(lang) {
      window.open('/docs/aruba_health_app_privacy_statement_' + lang + '.pdf', '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.privacy-policy {
  h2 {
    color: $primary;
  }
}
</style>
