<template>
  <div class="terms-of-use">
    <MainMenu />
    <div class="container mt-5 text-left">
      <div class="row">
        <div class="col-lg-8">
          <h2 class="font-weight-bold mb-3">Terms of Use</h2>
          <p class="p-text-large">Aruba Health App's Terms of Use outline the rules and guidelines for using the AHA and the services offered.</p>
        </div>
      </div>
      <hr class="mb-4" />
      <div class="row mt-3">
        <div class="col-lg-6">
          <ul>
            <li>
              <a href="#" @click="downloadTermsOfService('en')">Aruba Health App Terms of Use (English)</a>
            </li>
            <li>
              <a href="#" @click="downloadTermsOfService('nl')">Gebruiksvoorwaarden Aruba Health App (Nederlands)</a>
            </li>
            <li>
              <a href="#" @click="downloadTermsOfService('es')">T&eacute;rminos de Uso de la aplicaci&oacute;n Aruba Health (Espa&ntilde;ol)</a>
            </li>
            <li>
              <a href="#" @click="downloadTermsOfService('pap')">Condicion di Uzo Aruba Health App (Papiamento)</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from '@/components/main-menu.vue';
export default {
  components: {
    MainMenu,
  },
  methods: {
    downloadTermsOfService(lang) {
      window.open('/docs/aruba_health_app_terms_of_use_' + lang + '.pdf', '_blank');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.terms-of-use {
  h2 {
    color: $primary;
  }
}
</style>
